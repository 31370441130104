<template>
  <!-- Normal Booking -->
  <div v-if="!$route.query.type || $route.query.type === 'booking'">
    <div class="w-full mx-auto px-4 mb-20">
      <div class="max-w-6xl text-center mx-auto container mt-16">
        <h1 class="text-4xl text-center text-enquirytitle pb-2">
          Send Inquiry
        </h1>
        <span class="text-sm text-gray-600">
          Send us on enquiry and we'll reply with the best offers in 12 hours.
        </span>

        <div class="md:flex gap-20">
          <div class="w-full md:w-1/2">
            <!-- ENQUIRY FORM -->

            <form class="w-full text-gray-500 mt-10">
              <!-- <input type="text" placeholder="Niyama Private Island" class="border-b-2 border-enquiryborder py-3 px-2 w-full outline-none my-3"  /> -->

              <SearchDropDown
                :class="{ 'input-error': errors.property }"
                :value="booking.property"
                @valueChanged="propertyValChanged"
                :options="propertyOptions"
                placeholder="Select Property"
              />
              <SearchDropDown
                :class="{ 'input-error': errors.roomType }"
                :value="booking.roomType"
                @valueChanged="roomTypeValChanged"
                :options="roomTypeOptions"
                placeholder="Select Room Type"
              />

              <span class="md:flex justify-betwwen items-center gap-8">
                <span class="w-full"
                  ><input
                    :class="{ 'input-error': errors.arrivalDate }"
                    v-model="booking.arrivalDate"
                    type="date"
                    class="
                      border-b-2 border-enquiryborder
                      py-3
                      px-2
                      w-full
                      outline-none
                      my-3
                    "
                  />
                </span>
                <input
                  v-model="booking.hp"
                  type="hidden"
                  class="
                    border-b-2 border-enquiryborder
                    py-3
                    px-2
                    w-full
                    outline-none
                    my-3
                  "
                />
                <span class="w-full"
                  ><input
                    :class="{ 'input-error': errors.departureDate }"
                    v-model="booking.departureDate"
                    type="date"
                    class="
                      border-b-2 border-enquiryborder
                      py-3
                      px-2
                      w-full
                      outline-none
                      my-3
                    "
                  />
                </span>
              </span>

              <span class="md:flex justify-betwwen items-center gap-8">
                <span class="w-full"
                  ><input
                    :class="{ 'input-error': errors.adults }"
                    v-model="booking.adults"
                    type="text"
                    placeholder="No. of Adults"
                    class="
                      border-b-2 border-enquiryborder
                      py-3
                      px-2
                      w-full
                      outline-none
                      my-3
                    "
                  />
                </span>
                <span class="w-full"
                  ><input
                    :class="{ 'input-error': errors.children }"
                    v-model="booking.children"
                    type="text"
                    placeholder="No. of Children"
                    class="
                      border-b-2 border-enquiryborder
                      py-3
                      px-2
                      w-full
                      outline-none
                      my-3
                    "
                  />
                </span>
              </span>

              <input
                :class="{ 'input-error': errors.name }"
                v-model="booking.name"
                type="text"
                placeholder="Full Name"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <input
                :class="{ 'input-error': errors.nationality }"
                v-model="booking.nationality"
                type="text"
                placeholder="Nationality"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <input
                :class="{ 'input-error': errors.phone }"
                v-model="booking.phone"
                type="text"
                placeholder="Phone Number (Optional)"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <input
                :class="{ 'input-error': errors.email }"
                v-model="booking.email"
                type="email"
                placeholder="Email Address"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <select
                :class="{ 'input-error': errors.budget }"
                v-model="booking.budget"
                placeholder="Select Budget"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              >
                <option value="" disabled>Select Budget</option>
                <option value="Less than $2000" disabled>
                  Less than $2000
                </option>
                <option value="$2000-$4000">$2000-$4000</option>
                <option value="$4000-$6000">$4000-$6000</option>
                <option value="$6000-$8000">$6000-$8000</option>
                <option value="Above $8000">Above $8000</option>
              </select>
            </form>

            <div class="flex my-8 items-center">
              <button
                @click="book('booking')"
                class="
                  uppercase
                  text-white
                  bg-enquirybutton
                  hover:bg-enquiryborder
                  px-6
                  py-3
                  mr-3
                "
              >
                send inquiry
              </button>
              <Spinner :loading="loading" />
              <button class="text-sm pl-10 text-gray-600">
                or call +960 774-9407
              </button>
            </div>
            <div
              :class="{
                'text-green-500': message.type === 'success',
                'text-red-500': message.type === 'fail',
              }"
              class="font-sm text-left capitalize"
            >
              {{ message.message }}
            </div>

            <!-- ENQUIRY FORM / -->
          </div>

          <div class="w-full md:w-1/2">
            <!--    ENQUIRY PROPERTY CARD -->

            <EnquiryPreviewCard :property="selectedProperty" />

            <!-- ENQUIRY PROPERTY CARD / -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Normal Booking -->
  <!-- Package Booking -->
  <div v-if="$route.query.type === 'specialOffer'">
    <div class="w-full mx-auto px-4 mb-20">
      <div class="max-w-6xl text-center mx-auto container mt-16">
        <h1 class="text-4xl text-center text-enquirytitle pb-2">
          Send Inquiry
        </h1>
        <span class="text-sm text-gray-600">
          Send us on enquiry and we'll reply with the best offers in 12 hours.
        </span>

        <div class="md:flex gap-20">
          <div class="w-full md:w-1/2">
            <!-- ENQUIRY FORM -->

            <form class="w-full text-gray-500 mt-10">
              <!-- <input type="text" placeholder="Niyama Private Island" class="border-b-2 border-enquiryborder py-3 px-2 w-full outline-none my-3"  /> -->

              <SearchDropDown
                :class="{ 'input-error': offerErrors.package }"
                :value="offerBooking.package"
                @valueChanged="packageValChanged"
                :options="packageOptions"
                placeholder="Select Offer"
              />

              <span class="md:flex justify-betwwen items-center gap-8">
                <span class="w-full"
                  ><input
                    :class="{ 'input-error': offerErrors.arrivalDate }"
                    v-model="offerBooking.arrivalDate"
                    type="date"
                    class="
                      border-b-2 border-enquiryborder
                      py-3
                      px-2
                      w-full
                      outline-none
                      my-3
                    "
                  />
                </span>
                <span class="w-full"
                  ><input
                    :class="{ 'input-error': offerErrors.departureDate }"
                    v-model="offerBooking.departureDate"
                    type="date"
                    class="
                      border-b-2 border-enquiryborder
                      py-3
                      px-2
                      w-full
                      outline-none
                      my-3
                    "
                  />
                </span>
              </span>

              <input
                :class="{ 'input-error': offerErrors.name }"
                v-model="offerBooking.name"
                type="text"
                placeholder="Full Name"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <input
                :class="{ 'input-error': offerErrors.nationality }"
                v-model="offerBooking.nationality"
                type="text"
                placeholder="Nationality"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <input
                v-model="offerBooking.hp"
                type="hidden"
                placeholder="Nationality"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <input
                :class="{ 'input-error': offerErrors.phone }"
                v-model="offerBooking.phone"
                type="text"
                placeholder="Phone Number (Optional)"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <input
                :class="{ 'input-error': offerErrors.email }"
                v-model="offerBooking.email"
                type="email"
                placeholder="Email Address"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              />

              <select
                :class="{ 'input-error': offerErrors.budget }"
                v-model="offerBooking.budget"
                placeholder="Select Budget"
                class="
                  border-b-2 border-enquiryborder
                  py-3
                  px-2
                  w-full
                  outline-none
                  my-3
                "
              >
                <option value="" disabled>Select Budget</option>
                <option value="Less than $2000" disabled>
                  Less than $2000
                </option>
                <option value="$2000-$4000">$2000-$4000</option>
                <option value="$4000-$6000">$4000-$6000</option>
                <option value="$6000-$8000">$6000-$8000</option>
                <option value="Above $8000">Above $8000</option>
              </select>
            </form>

            <div class="flex items-center my-8">
              <button
                @click="book('specialOffer')"
                class="
                  uppercase
                  text-white
                  bg-enquirybutton
                  hover:bg-enquiryborder
                  px-6
                  py-3
                  mr-3
                "
              >
                send inquiry
              </button>
              <Spinner :loading="loading" />
              <button class="text-sm pl-10 text-gray-600">
                or call +960 774-9407
              </button>
            </div>
            <div
              :class="{
                'text-green-500': message.type === 'success',
                'text-red-500': message.type === 'fail',
              }"
              class="font-sm text-left capitalize"
            >
              {{ message.message }}
            </div>
            <!-- ENQUIRY FORM / -->
          </div>

          <div class="w-full md:w-1/2">
            <!--    ENQUIRY PROPERTY CARD -->

            <EnquiryPreviewCard
              :type="selectedPackage ? 'offer' : 'property'"
              :_package="selectedPackage"
              :property="selectedProperty"
            />

            <!-- ENQUIRY PROPERTY CARD / -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- / Package booking -->
</template>

<script>
import EnquiryPreviewCard from "@/components/layout/EnquiryPreviewCard";
import SearchDropDown from "@/components/base/SearchDropDown";

export default {
  name: "Enquiry",
  data() {
    return {
      booking: {
        property: this.$route.query.property || "",
        roomType: this.$route.query.roomtype || "",
        arrivalDate: "",
        departureDate: "",
        adults: "",
        children: "",
        name: "",
        nationality: "",
        phone: "",
        email: "",
        budget: "",
        hp: "",
      },
      offerBooking: {
        package: this.$route.query.offer || "",
        arrivalDate: "",
        departureDate: "",
        name: "",
        nationality: "",
        phone: "",
        email: "",
        budget: "",
        hp: "",
      },
      errors: {
        property: false,
        roomType: false,
        arrivalDate: false,
        departureDate: false,
        adults: false,
        children: false,
        name: false,
        nationality: false,
        // phone: false,
        email: false,
        budget: false,
      },
      offerErrors: {
        package: false,
        arrivalDate: false,
        departureDate: false,
        name: false,
        nationality: false,
        // phone: false,
        email: false,
        budget: false,
      },
      properties: [],
      packages: [],

      // UI Message
      message: {
        type: "",
        message: "",
      },
      loading: false,
    };
  },
  methods: {
    async book(type) {
      if (!type || type === "booking") {
        try {
          const valid = this.validate();

          if (valid) {
            this.loading = true;
            const res = await this.$axios.post(
              `${this.$api_v1}/bookings`,
              this.booking
            );

            if (res.status === 201) {
              this.message = {
                type: "success",
                message:
                  "enquiry recieved. our team will get back to you soon!",
              };
              this.booking = {
                property: this.$route.query.property || "",
                roomType: this.$route.query.roomtype || "",
                arrivalDate: "",
                departureDate: "",
                adults: "",
                children: "",
                name: "",
                nationality: "",
                phone: "",
                email: "",
                budget: "",
                hp: "",
              };
            }
          }
          this.loading = false;
        } catch (e) {
          this.message = {
            type: "fail",
            message:
              "enquiry could not be completed at this time. please try again later",
          };

          this.loading = false;
          this.$errorHandler(e);
        }
      } else if (type === "specialOffer") {
        try {
          const valid = this.offerValidate();

          if (valid) {
            this.loading = true;
            const res = await this.$axios.post(
              `${this.$api_v1}/packagebookings`,
              this.offerBooking
            );

            if (res.status === 201) {
              this.message = {
                type: "success",
                message:
                  "enquiry recieved. our team will get back to you soon!",
              };
              this.offerBooking = {
                package: this.$route.query.offer || "",
                arrivalDate: "",
                departureDate: "",
                name: "",
                nationality: "",
                phone: "",
                email: "",
                budget: "",
                hp: "",
              };
            }
          }
          this.loading = false;
        } catch (e) {
          this.message = {
            type: "fail",
            message:
              "enquiry could not be completed at this time. please try again later",
          };

          this.loading = false;
          this.$errorHandler(e);
        }
      }
    },
    validate() {
      let error = false;
      for (const key in this.errors) {
        if (!this.booking[key]) {
          this.errors[key] = true;
          error = true;
        } else {
          this.errors[key] = false;
        }
      }

      if (error) {
        this.message = {
          type: "fail",
          message: "please make sure required fields are completed",
        };
        return false;
      } else {
        return true;
      }
    },
    offerValidate() {
      let error = false;
      for (const key in this.offerErrors) {
        if (!this.offerBooking[key]) {
          this.offerErrors[key] = true;
          error = true;
        } else {
          this.offerErrors[key] = false;
        }
      }

      if (error) {
        this.message = {
          type: "fail",
          message: "please make sure required fields are completed",
        };
        return false;
      } else {
        return true;
      }
    },
    async getProperties() {
      try {
        const res = await this.$axios.get(
          `${this.$api_v1}/properties?getAll=true`
        );

        if (res.status === 200) {
          this.properties = res.data.properties;
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async getPackages() {
      try {
        const res = await this.$axios.get(
          `${this.$api_v1}/packages?getAll=true`
        );

        if (res.status === 200) {
          this.packages = res.data.packages;
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    propertyValChanged(id) {
      this.booking.property = id;
    },
    roomTypeValChanged(id) {
      this.booking.roomType = id;
    },
    packageValChanged(id) {
      this.offerBooking.package = id;
    },
  },
  watch: {
    "booking.property": {
      handler() {
        this.booking.roomType = "";
      },
    },
    "message.message": {
      handler() {
        setTimeout(() => {
          this.message = {
            type: "",
            message: "",
          };
        }, 6000);
      },
    },
  },
  computed: {
    propertyOptions() {
      if (this.properties && this.properties.length) {
        return this.properties.map((property) => {
          return {
            name: property.name,
            value: property._id,
          };
        });
      } else {
        return [];
      }
    },
    packageOptions() {
      if (this.packages && this.packages.length) {
        return this.packages.map((p) => {
          return {
            name: p.name,
            value: p._id,
          };
        });
      } else {
        return [];
      }
    },
    roomTypeOptions() {
      const index = this.properties.findIndex((property) => {
        return property._id === this.booking.property;
      });

      if (index === -1) return [];

      if (
        !this.properties[index].roomTypes ||
        !this.properties[index].roomTypes.length
      ) {
        return [];
      }

      return this.properties[index].roomTypes.map((room) => {
        return {
          name: room.name,
          value: room._id,
        };
      });
    },
    selectedProperty() {
      const index = this.properties.findIndex((property) => {
        return property._id === this.booking.property;
      });

      if (index === -1) return null;

      return this.properties[index];
    },
    selectedPackage() {
      const index = this.packages.findIndex((p) => {
        return p._id === this.offerBooking.package;
      });

      if (index === -1) return null;

      return this.packages[index];
    },
  },
  mounted() {
    this.getProperties();
    this.getPackages();
  },
  components: { EnquiryPreviewCard, SearchDropDown },
};
</script>

<style lang="scss" scoped>
.villa-card {
  &:nth-child(even) {
    .villa-card--image-container {
      order: -1;
    }
  }
}
</style>  

 