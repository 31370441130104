<template>
  <div
    @click="changeVisibility(false)"
    v-show="visible"
    class="dropdown-overlay"
  ></div>
  <div
    class="
      relative
      bg-white
      border-b-2 border-enquiryborder
      w-full
      outline-none
      my-3
    "
    :class="classes"
  >
    <div
      @focus.prevent="changeFocusToSearch"
      :class="{ 'bg-gray-100': disabled }"
      @click="disabled ? null : changeVisibility(true)"
      class="text-left w-full cursor-pointer text-gray-400 py-3 px-2"
    >
      {{ nameOfValue || placeholder }}
    </div>

    <div
      style="visibility: hidden"
      ref="optionsDiv"
      class="
        z-50
        dropdown-items
        slim-scrollbar
        absolute
        bg-white
        w-full
        text-left
        border
        rounded
      "
    >
      <input
        v-model="searchValue"
        ref="searchInput"
        type="text"
        class="w-full bg-gray-100 outline-none rounded py-2 px-4"
      />
      <div
        class="hover:bg-gray-100 cursor-pointer"
        v-for="(option, i) in filteredOptions"
        :key="i"
        @click="selectOption(option.value)"
      >
        {{ option.name }}
      </div>
      <div v-if="!options || !options.length">Options unavailable...</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchDropDown",
  data: function () {
    return {
      visible: false,
      searchValue: "",
    };
  },
  //   Options are an object with a 'name' and a 'value'
  props: ["value", "placeholder", "options", "disabled", "class"],
  emits: ["valueChanged"],
  methods: {
    selectOption(value) {
      this.changeVisibility(false);
      this.$emit("valueChanged", value);
    },
    changeVisibility(val) {
      this.visible = val;
      if (val === true) {
        this.$refs.optionsDiv.style.visibility = "visible";
        this.$refs.searchInput.focus();
      } else {
        this.$refs.optionsDiv.style.visibility = "hidden";
      }
    },
  },
  computed: {
    nameOfValue() {
      if (!this.value || !this.options || !this.options.length)
        return undefined;

      const index = this.options.findIndex((item) => item.value === this.value);

      if (index === -1) return undefined;

      return this.options[index].name;
    },
    filteredOptions() {
      if (!this.options || !this.options.length) return [];

      return this.options.filter((option) => {
        return option.name.match(new RegExp(this.searchValue, "gi"));
      });
    },
    classes() {
      if (!this.class || !this.class.length) return [];

      return this.class.split(" ");
    },
  },
};
</script>

<style scoped>
#optionsDiv {
  max-height: 300px;
  overflow-y: auto;
}
.dropdown-overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.dropdown-items {
  overflow-y: auto;
  max-height: 300px;
}
.dropdown-items > div {
  padding: 0.5rem 1rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}
</style>