<template>
  <!-- Property -->
  <div
    v-if="(!type || type === 'property') && property"
    class="border-enquiryborder border-2 mt-16 text-left"
  >
    <div
      :style="{
        backgroundImage: `url(${$api}/uploads/${property.featuredImage})`,
      }"
      class="preview-card-photo bg-center bg-no-repeat bg-cover"
    ></div>

    <div class="p-8 md:p-12 text-gray-700">
      <h1 class="text-2xl">{{ property.name }}</h1>

      <div class="flex">
        <div class="w-1/4 text-left">
          <p class="text-xs text-enquirytitle mt-6">STAR RATING</p>
        </div>
        <div class="w-full text-left">
          <p class="text-xs text-gray-500 mt-6">
            <span class="text-black uppercase">
              {{ property.starRating ? property.starRating.name : "" }}</span
            >
          </p>
        </div>
      </div>

      <div class="flex">
        <div class="w-1/4 text-left">
          <p class="text-xs text-gray-500 mt-3">TRANSFER</p>
        </div>
        <div class="w-full text-left">
          <p class="text-xs text-gray-500 mt-3 pl-3 md:pl-0">
            <span class="text-black uppercase">
              {{
                property.transferType ? property.transferType.name : ""
              }}</span
            >
          </p>
        </div>
      </div>
      <!-- 
        <p class="mt-8 text-xs">
          Escape to beachside hideaway surrounded by an abundance of indigenous,
          tropical flora. A typical morning greets you with views of waves.
          Enchanting sun and winds.
        </p> -->

      <div class="relative">
        <div
          v-html="property.description"
          class="mt-6 pcard-description text-xs items-center"
        ></div>
        <div class="pcard-description-overlay"></div>
      </div>
    </div>
  </div>
  <!-- / Property -->
  <!-- Offer -->
  <div
    v-if="type === 'offer' && _package"
    class="border-enquiryborder border-2 mt-16 text-left"
  >
    <div
      :style="{
        backgroundImage: `url(${$api}/uploads/${_package.featuredImage})`,
      }"
      class="preview-card-photo bg-center bg-no-repeat bg-cover"
    ></div>

    <div class="p-8 md:p-12 text-gray-700">
      <h1 class="text-2xl">{{ _package.name }}</h1>

      <div class="flex mt-6">
        <div class="w-1/2 lg:w-2/5 text-left">
          <p class="text-xs text-enquirytitle">BOOK BEFORE</p>
        </div>
        <div class="lg:w-full text-left">
          <p class="text-xs text-gray-500">
            <span class="text-black uppercase">
              {{ $formatDate(_package.bookBefore) }}</span
            >
          </p>
        </div>
      </div>

      <div class="flex mt-3">
        <div class="w-1/2 lg:w-2/5 text-left">
          <p class="text-xs text-enquirytitle">TRAVEL BEFORE</p>
        </div>
        <div class="lg:w-full text-left">
          <p class="text-xs text-gray-500 pl-3 md:pl-0">
            <span class="text-black uppercase">
              {{ $formatDate(_package.stayBetweenEnd) }}</span
            >
          </p>
        </div>
      </div>
      <!-- 
        <p class="mt-8 text-xs">
          Escape to beachside hideaway surrounded by an abundance of indigenous,
          tropical flora. A typical morning greets you with views of waves.
          Enchanting sun and winds.
        </p> -->

      <div class="relative">
        <div
          v-html="_package.description"
          class="mt-6 pcard-description text-xs items-center"
        ></div>
        <div class="pcard-description-overlay"></div>
      </div>
    </div>
  </div>
  <!-- / offer -->
</template>



<script>
export default {
  name: "EnquiryPropertyCard",
  props: ["type", "property", "_package"],
};
</script>

<style scoped>
.pcard-description {
  max-height: 160px;
  overflow: hidden;
}
.pcard-description-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 70%,
    rgba(255, 255, 255, 1) 100%
  );
}

.preview-card-photo {
  min-height: 300px;
}

@media (max-width: 940px) {
  .preview-card-photo {
    min-height: 250px;
  }
}

@media (max-width: 850px) {
  .preview-card-photo {
    min-height: 220px;
  }
}

@media (max-width: 768px) {
  .preview-card-photo {
    min-height: 300px;
  }
}

@media (max-width: 485px) {
  .preview-card-photo {
    min-height: 250px;
  }
}

@media (max-width: 400px) {
  .preview-card-photo {
    min-height: 200px;
  }
}
</style>